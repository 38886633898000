.wrapper {
  padding: 0 0 0 var(--spacing-medium);
}

.inner {
  display: flex;
  padding: var(--spacing-medium) 0;
  /* background: rgba(255, 255, 255, 0.95); */
  background-color: var(--color-grey);
  border-radius: var(--spacing-x-large) 0 0 var(--spacing-x-large);
  overflow-x: scroll;
}

.inner.isOpen {
  border-top-left-radius: 0;
}

.inner.productIsSelected {
  border-radius: 0 0 0 var(--spacing-x-large);
}
.list {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 var(--spacing-medium);
}

.listItem {
  padding-left: var(--spacing-small);
}

.category {
  padding-left: var(--spacing-medium);
  margin: 0 var(--spacing-medium) 0 0;
  font-size: 1.4rem;
  justify-self: start;
  line-height: 125%;
  white-space: nowrap;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .category > br {
    display: none;
  }
}

.button {
  width: auto;
  height: 3rem;
  padding: 0 var(--spacing-medium);
  background-color: var(--color-black);
  border-radius: var(--spacing-large);
  color: var(--color-white);
  text-transform: capitalize;
  white-space: nowrap;
}

.button.isActive {
  background-color: var(--color-pink);
  color: var(--color-black);
}

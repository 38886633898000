.wrapper {
    position: relative;
    z-index: 1;
    width: calc(100% - var(--spacing-medium));
    height: 0;
    margin: 0 0 0 auto;
    /* background: rgba(216, 216, 216, 0.8); */
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: var(--spacing-x-large) 0 0 0;
    opacity: 0;
}

.wrapper.isLookItem {
    height: auto;
    min-height: 7rem;
    background: transparent;
    opacity: 1;
}

.wrapper.detailIsSelected {
    height: auto;
    min-height: 7rem;
    opacity: 1;
}

.inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-medium) var(--spacing-large);
    border-radius: var(--spacing-x-large) 0 0 0;
}

.inner.isLookItem {
    max-height: none;
    padding-left: 0;
}

.loader {
    position: absolute;
    top: 50%;
    left: calc(var(--spacing-large) * 2);
    transform: translate(-50%);
}

.shadeFinderButtons {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
}

.shadeFinderButton {
    background-color: var(--color-white);
    border-radius: 7px;
}

.howToButton {
    padding: 1.6rem 1.2rem;
    font-size: 1rem;
    font-weight: bold;
    line-height: 100%;
    white-space: nowrap;
}

.cartButton {
    width: 4rem;
    padding: 1.2rem;
    margin-left: 1rem;
}

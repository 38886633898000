.header {
    padding: 0 var(--spacing-large);
}

.heading {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 150%;
    white-space: nowrap;
}

.shadeLabel {
    margin-right: 0.2em;
}

.subheading {
    font-size: 1.1rem;
    font-weight: normal;
    line-height: 150%;
    white-space: nowrap;
}

.list {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
}

.listCentered {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.listItem {
    padding-right: var(--spacing-medium);
}

.button {
    display: flex;
    width: 4rem;
    height: 4rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--color-white);
    font-size: 2.4rem;
}

.button.isNails {
    background-image: url(../../../../public/images/swatch-base.png);
    background-size: 4.5rem;
    background-repeat: no-repeat;
    background-position: center;
}

.button.isShadeFinder {
    color: var(--color-black);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .list {
        margin-left: 2rem;
    }
}

.button.isActive {
    border: 3px var(--color-white) solid;
    transform: scale(1.25);
}

.button.isNailsActive {
    background-size: 4rem;
}

.skuName {
    font-size: 0.8rem;
    font-weight: normal;
}

@import 'fonts.css';
@import 'variables.css';

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
}

html,
body {
  overflow: hidden;
  height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  font-family: 'Gucci Serif', serif;
}

body {
  background-color: var(--color-pink);
  color: var(--color-black);
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 160%;
  text-size-adjust: none;
  -moz-osx-text-smoothing: grayscale;
}

button {
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  -webkit-tap-highlight-color: transparent;
}

button:focus {
  box-shadow: none;
  outline: none;
}

img,
svg {
  display: block;
  max-width: 100%;
}

li {
  list-style: none;
}

.cookie-custom {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  color: var(--color-white) !important;
  font-size: 1.1rem !important;
  line-height: 1.1rem !important;
  transition: none !important;
}

.cookie-hide {
  opacity: 0 !important;
  pointer-events: none !important;
  transition: none !important;
  visibility: hidden !important;
}

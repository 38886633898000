:root {
  --color-black: #000000;
  --color-black-transparent: rgba(0, 0, 0, 0.5);
  --color-white: #ffffff;
  --color-grey: rgb(233, 233, 233);
  --color-pink: #ebbfb1;
  --spacing-x-small: 0.5rem;
  --spacing-small: 0.8rem;
  --spacing-medium: 1.2rem;
  --spacing-large: 1.8rem;
  --spacing-x-large: 3rem;
}

.wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-pink);
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  display: inline-block;
  width: 10rem;
  margin-bottom: 0.8rem;
}

.wrapper {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-black-transparent);
  font-size: 1.4rem;
}

.modal {
  display: flex;
  max-width: 60rem;
  height: 45rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5rem;
  margin: 0 auto;
  background-color: var(--color-white);
  text-align: center;
}

.text {
  position: relative;
  margin-bottom: 2rem;
}

.text::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5rem;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: '';
}

.textInner {
  height: 25rem;
  padding-bottom: 5rem;
  font-size: 1.2rem;
  line-height: 160%;
  overflow-y: scroll;
}

.textInner > a {
  color: var(--color-black);
  text-decoration: underline;
}

.button {
  min-width: 12rem;
  max-width: 30rem;
  padding: 0.8rem 5rem;
  border: 1px var(--color-black) solid;
  margin: 0 auto;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.buttonIcon {
  margin-left: 0.6rem;
}

.submitButton {
  opacity: 0.3;
  transition: opacity 0.2s;
}

.submitButton.isSelectable {
  opacity: 1;
}

.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.checkboxInput {
  position: relative;
  z-index: 1;
  width: 1.8rem;
  height: 1.8rem;
  opacity: 0;
}

.checkboxMask {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  display: flex;
  width: 1.8rem;
  height: 1.8rem;
  align-items: center;
  justify-content: center;
  border: 1px var(--color-black) solid;
}

.checkboxText {
  display: flex;
  margin-left: 0.8rem;
  font-size: 1.2rem;
  line-height: 2rem;
}

.exit {
  margin: 0 0 1rem auto;
}

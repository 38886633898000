.wrapper {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-black-transparent);
}

.modal {
    position: relative;
    max-width: 32rem;
    max-height: 80vh;
    margin: auto;
    background-color: var(--color-white);
    border-radius: 10px;
    overflow-y: scroll;
}

.exitButton {
    position: absolute;
    z-index: 3;
    top: 2rem;
    right: 2rem;
    width: 2.7rem;
    height: 2.7rem;
    background-color: var(--color-black-transparent);
    border-radius: 50%;
    color: var(--color-white);
}

.media {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.image {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
}

.videoButton {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.video {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
}

.details {
    padding: 2rem;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;
}

.name {
    /*max-width: 48%;*/
    font-size: 1.2rem;
    line-height: 1.6rem;
}

.cartButton {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: var(--color-white);
    border-radius: 7px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    font-size: 1rem;
    font-weight: bold;
    line-height: 100%;
}

.cartButtonLabel {
    margin-right: 1.5rem;
}

.cartButtonIcon {
    width: 1.2rem;
    margin-left: 0.5rem;
}

.title {
    margin-bottom: 1rem;
    font-size: 1.7rem;
    line-height: 2.4rem;
}

.description {
    position: relative;
    overflow: hidden;
    font-size: 1.1rem;
    line-height: 1.6rem;
    white-space: pre-wrap;
}

.descriptionText {
    width: 100%;
    border: 1px red solid;
}

.descriptionEllipsis {
    position: absolute;
    right: 0;
    width: 1.3rem;
    background: var(--color-white);
    text-align: right;
}

.descriptionButton {
    margin: 0.5rem 0 0 0;
    opacity: 0.7;
    text-decoration: underline;
}

.menuWrapper {
    margin: 0 2rem 2rem 2rem;
}

.menuHeading {
    margin-bottom: 0.4rem;
    font-size: 1.3rem;
}

.menu {
    overflow-x: scroll;
}

.menuItems {
    white-space: nowrap;
}

.menuItem {
    display: inline-block;
    width: 10rem;
    height: 10rem;
    margin-right: 1rem;
}

.menuItem:last-child {
    margin-right: 0;
}

.menuItemImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-x-large);
  margin: 0 auto;
  color: var(--color-black);
}

.text {
  padding-bottom: 5rem;
  font-size: 1.3rem;
  line-height: 180%;
  margin-bottom: 5rem;
  overflow: scroll;
}

.text > p > a {
  color: var(--color-black);
  text-decoration: underline;
}

.button {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

::-webkit-scrollbar {
  display: none;
}
.wrapper {
  display: flex;
  padding: var(--spacing-large);
  padding-bottom: 3rem;
  overflow-x: scroll;
}

.listItem:not(:last-child) {
  margin-right: var(--spacing-small);
}

.button {
  padding: var(--spacing-x-small) var(--spacing-medium);
  border: 2px var(--color-white) solid;
  background-color: var(--color-black-transparent);
  border-radius: var(--spacing-large);
  color: var(--color-white);
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
}

.button.isActive {
  background-color: var(--color-white);
  color: var(--color-black);
}

.activeMarker {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.5rem;
  border-radius: 50%;
}

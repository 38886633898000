.wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.text {
  font-size: 1.6rem;
  text-transform: uppercase;
}

.wrapper {
    display: flex;
    /* overflow: hidden; */
    align-items: center;
    justify-content: center;
    padding: var(--spacing-medium) var(--spacing-large) var(--spacing-large)
    var(--spacing-large);
    overflow-x: scroll;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .wrapper {
        padding-top: var(--spacing-x-large);
        padding-bottom: var(--spacing-x-large);
    }
}

.buttons {
    display: flex;
    justify-content: center;
}

.button {
    display: flex;
    width: 16rem;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0;
    border: 2px var(--color-white) solid;
    background-color: var(--color-black-transparent);
    border-radius: var(--spacing-large);
    color: var(--color-white);
    font-size: 1.2rem;
    line-height: 100%;
    text-transform: uppercase;
    transition: all 0.5s ease-in-out;
    margin-right: 1.5rem;
}

/* .button:first-child {
  margin-right: 1.5rem;
} */

.button.isActive {
    border: 1px var(--color-white) solid;
    background-color: var(--color-white);
    color: var(--color-black);
}

.activeMarker {
    width: 3rem;
    height: 3rem;
    margin-left: 0.5rem;
    border-radius: 50%;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

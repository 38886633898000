.wrapper {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-pink);
}

.logo {
  position: relative;
  margin-bottom: 1.5rem;
}

.message {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.wrapper {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  right: 0;
  bottom: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.list {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 6.5rem;
  border: 2px var(--color-black) solid;
  background-color: var(--color-pink);
}

.listItem:not(:last-child) {
  margin-bottom: 2.4rem;
}

.language {
  cursor: pointer;
  font-size: 1.6rem;
  text-transform: uppercase;
}

.closeButton {
  position: absolute;
  top: var(--spacing-medium);
  right: var(--spacing-medium);
  width: 1.8rem;
  height: 1.8rem;
}

.closeButtonIcon {
  width: 2rem;
  height: 2rem;
  stroke: var(--color-black);
  stroke-width: 3px;
}

.wrapper {
  position: absolute;
  z-index: 0;
  height: 42vh;
  max-height: 500px;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.isTopLeft {
  top: 0;
  left: 0;
}

.isTopRight {
  top: 0;
  right: 0;
}

.isBottomLeft {
  bottom: 0;
  left: 0;
}

.isBottomRight {
  right: 0;
  bottom: 0;
}

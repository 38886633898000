.toggleButton {
  position: absolute;
  z-index: 1;
  top: 5.4rem;
  left: 2rem;
  display: flex;
  align-items: center;
}

.toggleButton.isShoppable {
  top: 5.5rem;
}

.toggleButtonIcon {
  width: 2.5rem;
  height: 2.5rem;
}

.toggleButtonLabel {
  margin-left: 0.5rem;
  font-size: 1.4rem;
}

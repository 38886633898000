.wrapper {
  padding: 0 0 0 var(--spacing-medium);
}

.itemInner {
  display: flex;
  height: 0;
  max-height: 28rem;
  flex-direction: column;
  background: rgba(216, 216, 216, 0.95);
  border-radius: var(--spacing-x-large) 0 0 var(--spacing-x-large);
  opacity: 0;
  overflow-y: scroll;
}

.itemInner.isOpen {
  height: auto;
  border-bottom-left-radius: 0;
  opacity: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-large) var(--spacing-large) 0 var(--spacing-large);
  margin-bottom: 5px;
}

.lookName {
  max-width: 50%;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.4rem;
  text-transform: uppercase;
}

.fullLookButton {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
  font-size: 1rem;
  font-weight: bold;
  line-height: 100%;
}

.fullLookButtonLabel {
  margin-right: 1.5rem;
}

.fullLookButtonIcon {
  width: 1.2rem;
  margin-left: 0.5rem;
}

.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product.isShoppable {
  padding: 0 var(--spacing-large);
}

.cartButton {
  display: flex;
  align-items: center;
  padding: 1.2rem;
  margin: 0 0 0 var(--spacing-large);
  background-color: var(--color-white);
  border-radius: 7px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
}

.wrapper {
  position: relative;
  z-index: 2;
  padding: 0 0 0 var(--spacing-medium);
}

.inner {
  display: flex;
  align-items: center;
  padding: var(--spacing-medium) 0;
  /* background: rgba(255, 255, 255, 0.9); */
  background-color: var(--color-grey);
  border-radius: var(--spacing-x-large) 0 0 var(--spacing-x-large);
  overflow-x: scroll;
}

.inner.topLeftIsRounded {
  border-radius: 0 0 0 var(--spacing-x-large);
}

.wrapper {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black-transparent);
  font-size: 1.4rem;
}

.modal {
  display: flex;
  width: 100%;
  min-height: 40vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: 0 var(--spacing-medium);
  background-color: var(--color-white);
  border-radius: 10px;
  text-align: center;
}

.message {
  margin-bottom: 2.4rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  text-transform: uppercase;
}

.button {
  min-width: 40vw;
  padding: 0.8rem 2rem;
  margin: 0 auto;
  background-color: var(--color-black);
  border-radius: 4rem;
  color: var(--color-white);
  text-transform: uppercase;
}

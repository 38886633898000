@font-face {
  font-display: swap;
  font-family: 'Gucci Serif';
  font-style: italic;
  font-weight: normal;
  src: url('../../public/fonts/GucciSerif-Italic.woff2') format('woff2'),
    url('../../public/fonts/GucciSerif-Italic.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Gucci Serif';
  font-style: normal;
  font-weight: normal;
  src: url('../../public/fonts/GucciSerif-Regular.woff2') format('woff2'),
    url('../../public/fonts/GucciSerif-Regular.woff') format('woff');
}

.wrapper {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black-transparent);
  font-size: 1.4rem;
}

.modal {
  min-width: 100%;
  padding: 4rem 2rem;
  background-color: var(--color-white);
  text-align: center;
}

.title {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  line-height: 2.4rem;
  text-transform: uppercase;
}

.text {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 2rem;
}

.button {
  min-width: 40vw;
  padding: 0.8rem 2rem;
  margin: 0 auto;
  background-color: var(--color-black);
  border-radius: 4rem;
  color: var(--color-white);
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-transform: uppercase;
}

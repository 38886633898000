.heading {
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 150%;
}

.subheading {
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 150%;
}

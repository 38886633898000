.wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  max-width: 25rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menuListItem:not(:last-child) {
  margin-bottom: 2.5rem;
}

.menuLink {
  cursor: pointer;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.wrapper {
  align-self: flex-start;
  padding: var(--spacing-small) var(--spacing-large);
  margin-left: 6.4rem;
  background-color: rgb(27, 27, 27);
  border-radius: var(--spacing-large) var(--spacing-large) 0 0;
  color: var(--color-white);
}

.text {
  margin-right: 0.6rem;
  font-size: 1.1rem;
  line-height: 100%;
  text-transform: uppercase;
}

.icon {
  width: 0.9rem;
  height: 0.9rem;
  stroke: var(--color-white);
  stroke-width: 3px;
  transform: rotate(180deg);
}

.icon.detailIsSelected {
  transform: rotate(0deg);
}

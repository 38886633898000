.wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menuListItem {
    text-align: center;
}

.menuListItem:not(:last-child) {
    margin-bottom: 2.5rem;
}

.stepHeading {
    margin-bottom: 0.6rem;
    font-size: 1.4rem;
    line-height: 2rem;
}

.stepBody {
    font-size: 1.6rem;
    font-size: 1.6rem;
    text-transform: uppercase;
}

.startButton {
    min-width: 22rem;
    padding: 0.8rem 2rem;
    background-color: var(--color-black);
    border-radius: 4rem;
    color: var(--color-white);
    text-transform: uppercase;
}

.termsBar {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 4rem;
    background-color: var(--color-black);
}

.termsButton {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    color: var(--color-white) !important;
    font-size: 1.1rem !important;
    line-height: 1.1rem !important;
    transition: none !important;
}

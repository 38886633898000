.wrapper {
  display: flex;
}

.dot {
  width: 0.6rem;
  height: 0.6rem;
  background-color: var(--color-black);
  border-radius: 50%;
}

.dot:not(:last-child) {
  margin-right: 0.8rem;
}

.dot.isBlack {
  background-color: var(--color-black);
}

.dot.isBlackTransparent {
  background-color: var(--color-black-transparent);
}

.dot.isWhite {
  background-color: var(--color-white);
}

.wrapper {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.loader {
  position: absolute;
  bottom: 10vh;
  left: 50%;
  transform: translateX(-50%);
}
